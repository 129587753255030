@import url("./assets/css/boxicons.min.css");
@import url("./assets/css/jquery.fancybox.min.css");
@import url("./assets/css/swiper-bundle.min.css");
@import url("react-datepicker/dist/react-datepicker.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/style.css");
@import url("./assets/css/responsive.css");
.testimonial-pagination .swiper-pagination-bullet {
  background: #ff4838;
  padding: 7px;
  opacity: unset;
}

.testimonial-pagination .swiper-pagination-bullet-active {
  background: #fff;
}

.testimonial-slider-two .slider-item {
  padding-bottom: 30px;
}
.margin-bottom-c {
  margin-bottom: 70px;
}
.red_color{
  background: #671111;
}
.green_color{
  background: #156711;
}
.gallary_hw{
  width: 100px;
  height: 300px;
}

.footer_gallary_hw{
  width: 100px;
  height: 90px;
}
